<template>
	<main class="main" id="main" v-bind:class="theme">
		<div class="section-report-bug" @click.prevent="reportProblem()"><button>Report bug<i class="fa-solid fa-bug" aria-hidden="true"></i></button></div>
		<ReportProblemModal /> 
		<div class="contility">
			<img v-bind:src="contility" alt="the contility" title="The contility" />
		</div>
		<!-- end contility -->
		<div class="flex-container">
			<!-- GET SIDEBAR -->
			<div class="sidebar__wrapper" id="sidebarWrapper">
				<Sidebar @toggleSidebar="toggleSidebar"></Sidebar>
			</div>
			<!-- end sidebar__wrapper -->
			<!-- MAIN CONTENT -->
			<div class="main-content">
				<div class="container">
					<router-view></router-view>
				</div>
				<!-- end container -->
			</div>
			<!-- end main-content -->
		</div>
		<!-- end flex-container -->
	</main>
</template>

<script>
import {mapGetters} from "vuex"
import Sidebar from "./../components/sidebar/Sidebar.vue"
import ReportProblemModal from './../components/tools/ReportProblemModal.vue';
import html2canvas from 'html2canvas';
//let main = null
//let sidebarWrapper = null

export default {
	data() {
		return {
			theme: "dashboard admin has-sidebar blue-bg",
			contility: require("./../assets/img/contility.svg"),
		}
	},
	computed: {
		...mapGetters("views", ["getSidebarOpenState"]),
	},
	methods: {
		toggleSidebar() {
			this.$store.dispatch("views/toggleSidebar")
		},
		async reportProblem() {
            try {
                // this.onClose();
				document.querySelector('.main-content').classList.add('lock-section');
				document.querySelector('.section-report-bug').classList.add('lock-section');
				document.querySelector('.sidebar').classList.add('lock-section');
                const canvas = await html2canvas(document.querySelector('body'), { 
                    windowWidth: window.innerWidth, 
                    windowHeight: window.innerHeight,
                    x: window.pageXOffset,
                    y: window.pageYOffset
                });
                // Convert the canvas to Blob
                canvas.toBlob((blob) => {
                    // Save the screenshot to the store
                    this.$store.commit('client/setReportProblem', {prop: 'screenshot', val: blob});
                    this.$store.commit('client/setReportProblem', {prop: 'modalOpen', val: true});
                });
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            }
        }
	},
	components: {
		Sidebar,
		ReportProblemModal,
	},
	mounted() {
		//main = document.getElementById("main")
		//sidebarWrapper = document.getElementById("sidebarWrapper")
	},
}
</script>
<style lang="scss">
@import "./src/assets/scss/dashboard.scss";
</style>
